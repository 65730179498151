import Chart from 'chart.js/auto';
// import { getRelativePosition } from 'chart.js/helpers'

;(function (window, document) {
    let countChart = document.getElementById("countChart");
    if (countChart) {
        let dataForGraphic = JSON.parse(countChart.dataset.values);
        console.log(dataForGraphic);
        let labels = [];
        let values = [];
        for (let i = 0; i < dataForGraphic.length; i++) {
            labels.push(dataForGraphic[i].date);
            values.push(dataForGraphic[i].count);
        }
        let oilData = {
            labels: labels,
            datasets: [
                {
                    label: 'График получения заданий от даты',
                    data: values,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1
                }]
        };

        new Chart(countChart, {
            type: 'line',
            data: oilData,
            options: {
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                        // labels: {
                        //     color: 'rgb(255, 99, 132)'
                        // }
                    }
                }
            }
        });
    }
})(window, document);
