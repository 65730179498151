import axios from 'axios'
import {notify} from "../components/Notify";
import {nodeFromString} from "../tools/NodeFromString";

export default function tournaments() {
    let container = document.getElementById('task-container')
    if (container) {
        function initSelector() {
            let list = document.getElementById('tasks'),
                count = container.querySelectorAll('.task-item').length
            function initAdd() {
                $("#task-selector")?.on('change', e => {
                    e.preventDefault()
                    let clientSelector = document.getElementById('task-selector')
                    if (clientSelector.value) {
                        addTask(clientSelector.value)
                    } else {
                        notify('Ошибка', 'danger', 'Для добавления выберите участника')
                    }
                })
            }
            function sorting(){
                let items = container.querySelectorAll('.js-item')
                Array.from(items).sort(function(a, b) {
                    // using ~~ to cast the value to a number instead of a string
                    a = ~~a.querySelector("input[type='number']").value
                    b = ~~b.querySelector("input[type='number']").value
                    return a - b
                }).forEach(function(n, i) {
                    n.style.order = i
                })
            }
            function addTask(taskId) {
                    if (container.querySelectorAll(`[data-task-id='${taskId}']`).length > 0) {
                        notify('Ошибка', 'danger', 'Задачка уже в списке')
                        return;
                    }
                    axios.get(`/admin/tournaments/task-add/${taskId}?index=${++count}`)
                        .then(result => {
                            let item = nodeFromString(result.data.data)
                            initItem(item)
                            list.append(item)
                            // item.classList.add('client-item')
                            // item.setAttribute('data-task-id', taskId)
                            sorting()
                        })
                        .catch(error => {
                            notify('Error', 'danger', error)
                        })

            }
            function initItem(item) {
                item.querySelector('.item-remove')?.addEventListener('click', e => {
                    e.preventDefault()
                    item.remove()
                })
                let sortInput = item.querySelector("input[type='number']")
                if (sortInput) {
                    sortInput.addEventListener('change', e => {
                        sorting()
                    })
                }
            }
            initAdd()
            initItems()
            function initItems() {
                let items = container.querySelectorAll('.js-item')
                Array.from(items).forEach(item => {
                    initItem(item)
                })
                sorting()
            }
        }
        initSelector()
    }
}

