import axios from 'axios'
import {notify} from "../components/Notify";

export default function mailings() {
    let container = document.getElementById('mailing-container')
    if (container) {
        function initSend() {
            container.querySelector('#send-test-mailing')?.addEventListener('click', e => {
                e.preventDefault()
                let text = document.querySelector('#mailing-text').value
                let chatId = container.querySelector('#testUsername').value
                axios.post(`/admin/mailings/send-test`, {
                    text: text,
                    chatId: chatId,
                })
                    .then(result => {
                        if (result.data.success) {
                            notify('Успешно', 'success', 'Сообщение успешно отправлено')
                        } else {
                            notify('Ошибка отправки', 'danger', result.data.error)
                        }
                    })
                    .catch(error => {
                        notify('Error', 'danger', error)
                    })
            })
        }
        initSend()
    }
}

