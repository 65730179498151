import axios from "axios";
import {notify} from "../components/Notify";

;(function (window, document) {
    let hotInputElements = document.querySelectorAll('.js-index-hot-input');
    hotInputElements.forEach(element => {
        element.addEventListener('change', subscribe);
    });
    function subscribe() {
        let id = this.getAttribute('data-id')
        axios.post(`/admin/tasks/${id}/change-type`, { type: this.value })
            .then(result => {
                let response = result.data;
                if (response.success) {
                    notify('Операция прошла успешно', 'success', "Обновленный тип: " + response.nowTypeLabel)
                }
            }, error => {
                notify('Error', 'danger', error.message)
            })
    }
})(window, document);
